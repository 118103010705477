import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// import app components
// import ProductsGrid from "components/productsGrid"

const CollectionsArchive = props => {
  //   const {
  //     data: { product }
  //   } = props

  return (
    <Container>
      <h1>COLLECTIONS ARCHIVE</h1>
    </Container>
  )
}

export default CollectionsArchive

const Container = styled.div``

export const CollectionQuery = graphql`
  query CollectionsArchiveQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
    }
  }
`
